import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";

function HomePage() {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [des, setDes] = useState("");
  const [LoadingBTN, setLoadingBTN] = useState(false);

  function HandelSubmit(e) {
    e.preventDefault();
    const data = { fullname, email, subject, des };
    setLoadingBTN(true);
    if (!fullname) {
      toast.info("لطفا نام خود را وارد کنید", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: { fontFamily: "Vazirmatn" },
      });
      setEmail("");
      setTimeout(() => {
        setLoadingBTN(false);
      }, 2000);
    }
    if (!email) {
      toast.info("لطفا ایمیل خود را وارد کنید", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: { fontFamily: "Vazirmatn" },
      });
      setEmail("");
      setTimeout(() => {
        setLoadingBTN(false);
      }, 2000);
    }
    if (!subject) {
      toast.info("لطفا موضوع خود را وارد کنید", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: { fontFamily: "Vazirmatn" },
      });
      setEmail("");
      setTimeout(() => {
        setLoadingBTN(false);
      }, 2000);
    }
    if (fullname && email && subject) {
      emailjs
        .send(
          "service_0ygu2b8",
          "template_eevbyfo",
          { fullname, email, subject, des },
          "Pq_bTlzIlELFaQzB9"
        )
        .then(
          function (response) {
            if (response.status == 200) {
              toast.success("درخواست با موفقیت ارسال شد.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                style: { fontFamily: "Vazirmatn" },
              });
              setFullname("");
              setEmail("");
              setSubject("");
              setDes("");
              setTimeout(() => {
                setLoadingBTN(false);
              }, 1000);
              return;
            }
            setLoadingBTN(false);
          },
          function (error) {
            console.log("FAILED...", error);
            toast.error("خطا در ارسال درخواست", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              style: { fontFamily: "Vazirmatn" },
            });
            setEmail("");
            setTimeout(() => {
              setLoadingBTN(false);
            }, 2000);
          }
        );
    }
  }
  return (
    <>
      <div className="min-h-screen py-6 flex flex-col justify-center sm:py-12">
        <div className="relative py-3 mx-auto w-11/12 md:w-8/12 xl:w-5/12">
          <div className="absolute inset-0 bg-gradient-to-r from-red-600 to-red-800 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 rounded-3xl"></div>
          <div className="relative px-4 p pb-10 pt-6 bg-gray-800 shadow-lg rounded-3xl">
            <div className="max-w-md mx-auto">
              <div className="divide-y divide-gray-200">
                <div className="py-8 text-base leading-6 sm:text-lg sm:leading-7">
                  <div className="relative">
                    <div className="text-center mb-2 text-gray-400">
                      درخواست مطلب
                    </div>
                    <form action="#" onSubmit={HandelSubmit} method="post">
                      <div className="flex flex-wrap">
                        <div className="p-1 w-6/12 h-12">
                          <input
                            onChange={(e) => {
                              setFullname(e.target.value);
                            }}
                            value={fullname}
                            autoComplete="off"
                            type="text"
                            className="pr-1 rounded-md h-full w-full text-sm border-gray-600 bg-gray-600 text-gray-900 focus:outline-none focus:borer-rose-600"
                            placeholder="نام و نام خانوادگی"
                          />
                        </div>
                        <div className="p-1 w-6/12 h-12">
                          <input
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            autoComplete="off"
                            value={email}
                            name="email"
                            type="email"
                            className="pr-1 rounded-md h-full w-full text-sm border-gray-600 bg-gray-600 text-gray-900 focus:outline-none focus:borer-rose-600"
                            placeholder="ایمیل"
                          />
                        </div>
                        <div className="p-1 w-full h-12">
                          <input
                            onChange={(e) => {
                              setSubject(e.target.value);
                            }}
                            value={subject}
                            autoComplete="off"
                            name="subject"
                            type="text"
                            className="pr-1 rounded-md h-full w-full text-sm border-gray-600 bg-gray-600 text-gray-900 focus:outline-none focus:borer-rose-600"
                            placeholder="موضوع"
                          />
                        </div>
                        <div className="p-1 w-full h-12 mb-10">
                          <textarea
                            onChange={(e) => {
                              setDes(e.target.value);
                            }}
                            value={des}
                            className="pr-1 pt-2 rounded-md w-full text-sm border-b-2 border-gray-600 bg-gray-600 text-gray-900 focus:outline-none focus:borer-rose-600"
                            name="des"
                            rows="4"
                            placeholder="توضیحات"
                          ></textarea>
                        </div>
                        <div className="w-full pt-3">
                          {LoadingBTN ? (
                            <button
                              className="bg-gray-700 hover:bg-red-600 transition-all ease-in-out rounded-lg py-1 px-8"
                              disabled
                            >
                              صبر کنید ...
                            </button>
                          ) : (
                            <button className="bg-gray-700 hover:bg-red-600 transition-all ease-in-out rounded-lg py-1 px-8">
                              ارسال
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
