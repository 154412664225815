import {Routes, Route } from "react-router-dom";
import HomePage from "./Components/Users/HomePage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage/>} />
      <Route path="/manage" element="" />
    </Routes>
  );
}

export default App;
